export const projects = [
  {
    name: "Clothing Store",
    imageURL: "https://i.imgur.com/TnNcVux.png",
    href: "https://awesome-shop-react.herokuapp.com/",
    type: "E-commerce",
  },
  {
    name: "The Perk",
    imageURL: "https://i.imgur.com/OnHNl7J.png",
    href: "https://app.getperk.co/",
    type: "Fintech",
  },
  {
    name: "FlashCards.com",
    imageURL: "https://i.imgur.com/c3goVy1.png",
    href: "https://github.com/ronan-f/flash-cards",
    type: "Education",
  },
]
