import React from "react"
import "../styles/project.scss"
import { ANIMATION_DURATION } from "../constants"

export default function Project({ imageURL, name, type, href }) {
  function handleOnClick() {
    return window.open(href, "_blank")
  }

  return (
    <div
      data-sal="fade"
      data-sal-easing="ease"
      data-sal-duration={ANIMATION_DURATION}
      className="project-container"
    >
      <div className="header">
        <div className="project-title">
          <h3>{name}</h3>
          <p>{type}</p>
        </div>
        <p onClick={handleOnClick} className="right-arrow">
          &#8594;
        </p>
      </div>
      <img src={imageURL} alt={`${name} project`} />
    </div>
  )
}
