import React from "react"
import "../styles/footer.scss"
import { FaGithub, FaLinkedin } from "react-icons/fa"
import { NAME, GITHUB, LINKEDIN } from "../constants"

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="social">
        <a href={GITHUB} target="_blank" rel="noreferrer">
          <FaGithub size={30} />
        </a>
        <a href={LINKEDIN} target="_blank" rel="noreferrer">
          <FaLinkedin size={30} />
        </a>
      </div>
      <div className="footer-copy">
        <p className="copyright">© {NAME} 2020</p>
      </div>
    </div>
  )
}
