import React from "react"
import "../styles/welcome.scss"
import { Link } from "gatsby"
import { ANIMATION_DURATION } from "../constants"

export default function Welcome() {
  return (
    <div
      data-sal="slide-left"
      data-sal-easing="ease"
      data-sal-duration={ANIMATION_DURATION}
      className="welcome-container"
    >
      <h1>Hi. I'm a software developer based in Vancouver, BC</h1>
      <p>
        Originally from Ireland, I've been living in Vancouver for the last few
        years immersing myself in the tech scene.
      </p>
      <p>
        My primary interest right now is developing my technical skillset with a
        focus on modern web technologies such as React and NodeJS. Please get in
        touch if you'd like to chat or discuss a project.
      </p>
      <br />
      <Link to="/#my-work">
        <button>
          <h1>Check out my work</h1>
        </button>
      </Link>
    </div>
  )
}
