import React from "react"
import "../styles/layout.scss"
if (typeof window !== "undefined") {
  const SmoothScroll = require("smooth-scroll")
  new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  })
}

export default function Layout({ children }) {
  return <div className="container">{children}</div>
}
