import React, { useState } from "react"
import { ANIMATION_DURATION } from "../constants"
import "../styles/contact.scss"

export default function Contact() {
  const defaultError = { isError: false, errMsg: "" }
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false)
  const [error, setError] = useState(defaultError)

  const handleOnSubmit = async e => {
    e.preventDefault()
    const form = e.target
    for (const el of form) {
      if (el.tagName !== "BUTTON" && !el.value) {
        console.log({ el })
        return setError({
          isError: true,
          errMsg: `Oops! There is a problem with the ${el.name} field. Please correct it and try again`,
        })
      }
    }

    const postURL = "https://getform.io/f/c8933c49-b598-4fd2-b3a8-cfced2df11a7"

    try {
      await fetch(postURL, {
        method: "POST",
        body: new FormData(form),
      })

      setError(defaultError)
      setHasFormSubmitted(true)
    } catch (e) {
      console.error(e)
      setError({ isError: true })
    }
  }

  const createError = msg => {
    if (!msg) {
      return (
        <p className="error">
          Oops! something went wrong submitting the form. Please try again
          or&nbsp;
          <a href="mailto:rfegan13@gmail.com">email me directly.</a>
        </p>
      )
    }
    return <p className="error">{msg}</p>
  }

  if (hasFormSubmitted) {
    return (
      <h1 className="form-success">
        Thanks for getting in touch. I'll get back to you shortly.
      </h1>
    )
  }

  return (
    <div
      data-sal="fade"
      data-sal-easing="ease"
      data-sal-duration={ANIMATION_DURATION}
      id="contact"
      className="form-container"
    >
      <h1>Want to get in touch?</h1>
      <p>Submit the form below and I'll get back to you soon!</p>

      {error.isError && createError(error.errMsg)}

      <form onSubmit={handleOnSubmit}>
        <label>
          Email
          <input type="email" name="email" />
        </label>
        <label>
          Name
          <input type="text" name="name" />
        </label>
        <label>
          Message
          <textarea type="text" name="message" />
        </label>
        <button type="submit">Contact Me</button>
      </form>
    </div>
  )
}
