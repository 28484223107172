import React from "react"
import "../styles/hugeTitle.scss"
import { NAME, ANIMATION_DURATION } from "../constants"

export default function HugeTitle() {
  const [firstWord, secondWord] = NAME.split(" ")
  return (
    <h1
      data-sal="slide-right"
      data-sal-easing="ease"
      data-sal-duration={ANIMATION_DURATION}
      className="huge-title"
    >
      {firstWord} <br /> {secondWord}
    </h1>
  )
}
