import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/header"
import Layout from "../components/layout"
import Welcome from "../components/welcome"
import HugeTitle from "../components/hugeTitle"
import Footer from "../components/footer"
import Project from "../components/project"
import Contact from "../components/contact"
import { projects } from "../projects"
import { ANIMATION_DURATION, GITHUB, LINKEDIN } from "../constants"

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ronan Fegan's Portfolio</title>
      </Helmet>
      <Header />
      <Layout>
        <HugeTitle />
        <Welcome />
      </Layout>
      <div className="grid-container">
        <div className="projects-container">
          <div
            data-sal="fade"
            data-sal-easing="ease"
            data-sal-duration={ANIMATION_DURATION}
            id="my-work"
            className="projects-description"
          >
            <h1>Work</h1>
            <p>
              Here is a small collection of projects I've worked on. These vary
              from small personal projects to professional applications I've
              worked on full time. I've tried to include a link to the hosted
              site or source code where appropriate.
            </p>
            <p>
              I'm constantly tinkering on various projects so check out my&nbsp;
              <a href={GITHUB} target="blank" rel="noreferrer">
                Github
              </a>{" "}
              or{" "}
              <a href={LINKEDIN} target="blank" rel="noreferrer">
                LinkedIn
              </a>{" "}
              to see what I'm up to.
            </p>
          </div>
          {projects.map(({ name, ...props }) => {
            return <Project name={name} {...props} key={name} />
          })}
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  )
}
